import React from "react"
import { Link, graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import BlockContent from "@sanity/block-content-to-react"
import serializers from "../components/serializers"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactForm from "../components/contactForm"
import PageOpening from "../components/pageOpening"

const Insights = () => {
  const data = useStaticQuery(graphql`
    query {
      sanityPages(id: { eq: "f04250e7-08ad-5a04-96b2-26f1261c7a3d" }) {
        seo {
          seo_title
          meta_description
        }
        title
        _rawBody
        _rawSubtitle
        templateList
        mainImage {
          alt
          image {
            asset {
              fluid(maxWidth: 600) {
                ...GatsbySanityImageFluid
              }
            }
          }
        }
      }
      allSanityPost(sort: { order: DESC, fields: publishedAt }, limit: 1) {
        edges {
          node {
            title
            subTitle
            _rawBody
            mainImage {
              image {
                asset {
                  fluid(maxWidth: 900) {
                    ...GatsbySanityImageFluid
                  }
                }
              }
              alt
            }
            slug {
              current
            }
          }
        }
      }
      allSanityNews(sort: { order: DESC, fields: publishedAt }, limit: 1) {
        edges {
          node {
            title
            subTitle
            _rawBody
            mainImage {
              image {
                asset {
                  fluid(maxWidth: 900) {
                    ...GatsbySanityImageFluid
                  }
                }
              }
              alt
            }
            slug {
              current
            }
          }
        }
      }
      allSanityCases(sort: { order: DESC, fields: publishedAt }, limit: 1) {
        edges {
          node {
            title
            subTitle
            _rawBody
            slug {
              current
            }
            mainImage {
              image {
                asset {
                  fluid(maxWidth: 900) {
                    ...GatsbySanityImageFluid
                  }
                }
              }
              alt
            }
          }
        }
      }
    }
  `)

  return (
    <Layout>
      {data.sanityPages.seo && (
        <SEO
          title={data.sanityPages.seo.seo_title}
          metaDescription={data.sanityPages.seo.meta_description}
          seoTitle={data.sanityPages.seo.seo_title}
        />
      )}
      <div className="sub_header">
        <Link activeClassName="active" to="/cases/">
          Cases
        </Link>
        <Link activeClassName="active" to="/blog/">
          Blog
        </Link>
        <Link activeClassName="active" to="/nyheder/">
          Nyheder
        </Link>
      </div>
      <section className="index insights">
        <PageOpening data={data} />

        <BlockContent
          className={data.sanityPages.templateList}
          blocks={data.sanityPages._rawBody}
          projectId="ppd47c0u"
          dataset="production"
          serializers={serializers}
        />

        <div className="insights_wrapper  ">
          {data.allSanityCases.edges.map(function(cases) {
            return (
              <Link className="fade_link" to={`/${cases.node.slug.current}/`}>
                <div className="single_insight ">
                  <div className="left_wrapper animation--fade-img animation">
                    <p className="category_mobile">Cases</p>
                    <Img
                      fluid={cases.node.mainImage.image.asset.fluid}
                      alt={cases.node.mainImage.alt}
                    />
                  </div>

                  <div className="right_wrapper animation--fade-up animation">
                    <p className="category_desktop">Cases</p>
                    <h2>{cases.node.title}</h2>
                    <div className="content">
                      <p>{cases.node.subTitle}</p>
                    </div>

                    <Link to={`/${cases.node.slug.current}/`}>Læs mere</Link>
                  </div>
                </div>
              </Link>
            )
          })}
          {data.allSanityPost.edges.map(function(insight) {
            return (
              <Link className="fade_link" to={`/${insight.node.slug.current}/`}>
                <div className="single_insight">
                  <div className="left_wrapper animation--fade-img animation">
                    <p className="category_mobile">Blog</p>
                    <Img
                      fluid={insight.node.mainImage.image.asset.fluid}
                      alt={insight.node.mainImage.alt}
                    />
                  </div>
                  <div className="right_wrapper animation--fade-up animation">
                    <p className="category_desktop">Blog</p>
                    <h2>{insight.node.title}</h2>
                    <div className="content">
                      <p>{insight.node.subTitle}</p>
                    </div>
                    <Link to={`/${insight.node.slug.current}/`}>Læs mere</Link>
                  </div>
                </div>
              </Link>
            )
          })}

          {data.allSanityNews.edges.map(function(news) {
            return (
              <Link className="fade_link" to={`/${news.node.slug.current}/`}>
                <div className="single_insight ">
                  <div className="left_wrapper animation--fade-img animation">
                    <p className="category_mobile">Nyheder</p>
                    <Img
                      fluid={news.node.mainImage.image.asset.fluid}
                      alt={news.node.mainImage.alt}
                    />
                  </div>
                  <div className="right_wrapper animation--fade-up animation">
                    <p className="category_desktop">Nyheder</p>
                    <h2>{news.node.title}</h2>
                    <div className="content">
                      <p>{news.node.subTitle}</p>
                    </div>

                    <Link to={`/${news.node.slug.current}/`}>Læs mere</Link>
                  </div>
                </div>
              </Link>
            )
          })}
        </div>
      </section>
      <ContactForm></ContactForm>
    </Layout>
  )
}

export default Insights
